import { Box, Text } from 'rebass'
import styled from 'styled-components'

export const Content = styled(Box)`
  color: white;
  position: relative;
  z-index: 50;
`
export const RegText = styled(Text)`
  opacity: 0.5;
`
export const ContactList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  & > li {
    display: block;
    font-weight: 700;
    margin-bottom: 0.85em;
  }
  svg {
    margin-right: 15px;
    transform: translateY(7px);
  }
`
