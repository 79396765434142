import React from 'react'
import styled, { css } from 'styled-components'

const width = '187'
const height = '120'
const viewBox = '0 0 187 120'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <g
      fill='none'
      fillRule='evenodd'
      key='key-0'
    >
      <path
        fill='#EDEDED'
        d='M0 0h187v120H0z'
      />
      <path
        fill='#212121'
        fillRule='nonzero'
        d='M92.5 40c5.296 0 5.96.023 8.042.119 7.088.325 11.023 4.267 11.341 11.342.094 2.08.117 2.743.117 8.039 0 5.296-.023 5.96-.119 8.04-.32 7.089-4.265 11.018-11.34 11.343-2.08.094-2.745.117-8.041.117s-5.959-.023-8.04-.117c-7.089-.325-11.018-4.26-11.343-11.342C73.023 65.46 73 64.796 73 59.5s.023-5.959.119-8.04c.325-7.089 4.26-11.018 11.342-11.343 2.08-.094 2.743-.117 8.039-.117zm0 9.487c-5.53 0-10.013 4.483-10.013 10.013S86.97 69.515 92.5 69.515s10.013-4.483 10.013-10.015c0-5.53-4.483-10.013-10.013-10.013zM92.5 66a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13zm10.41-19.248a2.341 2.341 0 1 0-.002 4.682 2.341 2.341 0 0 0 .002-4.682z'
      />
    </g>
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'ButtonContactIg'
})
