import React from 'react'
import styled, { css } from 'styled-components'

const width = '187'
const height = '120'
const viewBox = '0 0 187 120'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <g
      fill='none'
      fillRule='evenodd'
      key='key-0'
    >
      <path
        fill='#EDEDED'
        d='M0 0h187v120H0z'
      />
      <path
        fill='#1A1919'
        fillRule='nonzero'
        d='M108.595 43.358c-6.907-.478-22.292-.476-29.19 0-7.47.517-8.35 5.094-8.405 17.142.056 12.026.928 16.623 8.405 17.142 6.9.476 22.283.478 29.19 0 7.47-.517 8.35-5.094 8.405-17.142-.056-12.026-.928-16.623-8.405-17.142zM88.25 68.278V52.722l15.333 7.764-15.333 7.792z'
      />
    </g>
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'ButtonContactYt'
})
