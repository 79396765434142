import styled from 'styled-components'
import { media, easeOutExpo } from '../../theme/'
import ButtonContactFb from '../../vectors/ButtonContactFb'
import ButtonContactIg from '../../vectors/ButtonContactIg'
import ButtonContactTw from '../../vectors/ButtonContactTw'
import ButtonContactYt from '../../vectors/ButtonContactYt'
import ButtonContactBc from '../../vectors/ButtonContactBc'
export const Facebook = styled(ButtonContactFb)`

`
export const Instagram = styled(ButtonContactIg)`
 
`
export const Twitter = styled(ButtonContactTw)`


`
export const Youtube = styled(ButtonContactYt)`

  
`
export const Bandcamp = styled(ButtonContactBc)`

`

export const InlineUnorderedList = styled.ul`
  display: block;
  width: 100%;
  padding: 0;
  margin: 20px 0;
  list-style: none;
  text-align: left;
  position: relative;
  z-index: 2;
  
 
  svg {
    width: 100%;
    height: auto;
    transition: transform 0.3s ${easeOutExpo}, opacity 0.3s ${easeOutExpo};
    backface-visibility: hidden;
    &:hover {
      transform: scale(1.03);
      opacity: 0.9;
    }
  }
  > li {
    width: 48%;
    margin-right: 2%;
    ${media.MediumScreenUp`
      width: 32.333%;
      margin-right: 1%;
    `}
    ${media.LargeScreenUp`
      width: 16%;
    `}
    display: inline-block;
    
    & > a {
      display: block;
    }
    & > a:after {
      display: none;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`
