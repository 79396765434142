import React from 'react'
import styled, { css } from 'styled-components'

const width = '187'
const height = '120'
const viewBox = '0 0 187 120'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <g
      fill='none'
      fillRule='evenodd'
      key='key-0'
    >
      <path
        fill='#EDEDED'
        d='M0 0h187v120H0z'
      />
      <path
        fill='#212121'
        fillRule='nonzero'
        d='M88.25 54H83v6h5.25v18H97V60h6.374l.626-6h-7v-2.5c0-1.433.336-2 1.951-2H104V42h-6.664c-6.293 0-9.086 2.374-9.086 6.922V54z'
      />
    </g>
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'ButtonContactFb'
})
