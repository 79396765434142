import React from 'react'
import { Box, Text, Heading } from 'rebass'
import { RegText, ContactList } from './styles'
import { unescape } from 'lodash'
import ContentSocial from '../ContentSocial'
import PageContentHolder from '../PageContentHolder'
import IconContactPhone from '../../vectors/IconContactPhone'
import IconContactEmail from '../../vectors/IconContactEmail'
import Stripe from '../Stripe'

const Contact = (props) => {
  var { address, regnumber, email, telephone } = props.info

  return (
    <PageContentHolder heading={'Little Rocket Records Ltd'}>

      <Stripe full wide />
      <Box w={[1 / 1, null, 1 / 2]} pl={[0, null, '15.5%']} mt={[20, null, 60]}>

        { address && <Text fontSize={3} mt={-3} dangerouslySetInnerHTML={{ __html: unescape(address) }} /> }
        { regnumber && <RegText mt={[-20, 0, 30]} fontSize={2}>Registered in England. Number {regnumber}</RegText>}

      </Box>
      <Box w={[1 / 1, null, 1 / 2]} mt={[20, null, 60]}>
        <ContactList>
          { telephone && <li><IconContactPhone /> <a href={`tel:${telephone}`}>{telephone}</a></li> }
          { email && <li><IconContactEmail /> <a href={`mailto:${email}`}>{email}</a></li> }
        </ContactList>
      </Box>
      <Box w={1 / 1} pl={[0, null, '15.5%']} mt={[20, null, 60]}>
        <Heading className='sans' fontSize={3}>Find us on:</Heading>
        <ContentSocial />
      </Box>
    </PageContentHolder>
  )
}

export default Contact
