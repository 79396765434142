import React from 'react'
import { InlineUnorderedList, Facebook, Instagram, Twitter, Bandcamp, Youtube } from './styles'

const ContentSocial = (props) => {
  return (
    <InlineUnorderedList>
      <li><a href={`${process.env.SOCIAL_BANDCAMP}`} target='_blank' rel='noopener noreferrer'><Bandcamp alt='follow us on Bandcamp' /></a></li>
      <li><a href={`${process.env.SOCIAL_FACEBOOK}`} target='_blank' rel='noopener noreferrer'><Facebook alt='Like us on Facebook' /></a></li>
      <li><a href={`${process.env.SOCIAL_TWITTER}`} target='_blank' rel='noopener noreferrer'><Twitter alt='follow us on Twitter' /></a></li>
      <li><a href={`${process.env.SOCIAL_INSTAGRAM}`} target='_blank' rel='noopener noreferrer'><Instagram alt='follow us on Instagram' /></a></li>
      <li><a href={`${process.env.SOCIAL_YOUTUBE}`} target='_blank' rel='noopener noreferrer'><Youtube alt='follow us on Youtube' /></a></li>
    </InlineUnorderedList>
  )
}
export default ContentSocial
